import React from 'react'
import yoursportz from '../../assets/images/YoursportzLogo.jpg'
import yoursportzBt from '../../assets/images/yoursportzBT.jpg'
import yoursportzHk from '../../assets/images/yoursportzHk.png'
import chatverse from '../../assets/images/ChatVerse.jpg';
import '../../assets/scss/style.scss';

export const OurProducts = () => {
  return (
    <section id='products' className='products'>
        
          <h2>Explore Our Diverse Range of Apps</h2>
          <p className='upperP'>Discover the full range of apps we offer: 1. YourSportz - Sports management made easy. 2. ChatVerse - Seamless communication redefined. 3. UniPost - Simplify your content management.</p>
          <div className="productList">
          
            <div className="product">
              <img src={yoursportz} alt="" />
              <h5>YourSportz Football</h5>
              <p className='innerP'>YourSportz Football is a comprehensive sports platform tailored for football enthusiasts. Whether you're a player, coach, or fan, this app caters to your needs.
              </p>
              <button className='btn' onClick={() => window.location.href='https://yoursportz.in'}>Visit Website</button>
            </div>
            
            <div className="product">
              <img src={chatverse} alt="" />
              <h5>ChatVerse</h5>
              <p  className='innerP'>Real-time messaging, video calling, and creative video sharing enable instant, engaging communication, revolutionizing personal and professional digital interactions.
              </p>
              <button className='btn' onClick={() => window.location.href='https://chatverse.world'}>Visit Website</button>
            </div>
            <div className="product">
              <img src={yoursportzBt} alt="" />
              <h5>YourSportz Badminton</h5>
              <p  className='innerP'>YourSportz Badminton is a comprehensive sports platform tailored for Badminton enthusiasts. Whether you're a player, coach, or fan, this app caters to your needs.
              </p>
              <button className='btn'>Visit Website</button>
            </div>
            <div className="product">
              <img src={yoursportzHk} alt="" />
              <h5>YourSportz Hockey</h5>
              <p  className='innerP'>YourSportz Hockey is a comprehensive sports platform tailored for Hockey enthusiasts. Whether you're a player, coach, or fan, this app caters to your needs.
              </p>
              <button className='btn'>Visit Website</button>
            </div>
          </div>
        </section>
  )
}
