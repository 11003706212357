import React, { useEffect, useRef, useState } from 'react'

import { HashLink as Link } from 'react-router-hash-link';
import ham from '../../assets/images/menu1.png';
import logo from '../../assets/images/NavrekhTechLogo.jpg';
import close from '../../assets/images/close.png';
import '../../assets/scss/style.scss';
export const Header = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className={hasScrolled ? 'scrolled' : ''} ref={dropdownRef}>
      <div className="logo">
        <img src={logo} alt="" />
        <h3>Navrekh Tech</h3>
      </div>

      <nav className='outNav'>
        <ul>
          <li>
            <Link smooth to="#hero">Home</Link>
          </li>
          <li>
            <Link smooth to="#products">Our Products</Link>
          </li>
          <li>
            <Link smooth to="#about-us">About Us</Link>
          </li>
          <li>
            <Link smooth to="#contactUs">Contact Us</Link>
          </li>
        </ul>
      </nav>

      <img src={ham} alt="" className='ham' id="ham" onClick={toggleMenu} />


      <div className={isMenuOpen ? 'menuOpen' : 'menuNotOpen'}>

        <div className="top">
          <h3>Navrekh Tech</h3>
          <img src={close} alt="" className='closeImg' onClick={toggleMenu} />
        </div>

        <nav>
          <ul>
            <li >
              <Link onClick={toggleMenu} smooth to="#hero">Home</Link>
            </li>
            <li>
              <Link onClick={toggleMenu} smooth to="#products">Our Products</Link>
            </li>
            <li>
              <Link onClick={toggleMenu} smooth to="#about-us">About Us</Link>
            </li>
            <li>
              <Link onClick={toggleMenu} smooth to="#contactUs">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>

    </header>
  )
}
