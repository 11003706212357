import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
// import { AboutUs } from "./pages/AboutUs/AboutUs";
// import { OurProducts } from "./pages/OurProducts/OurProducts";
// import { ContactUs } from "./pages/ContactUs/ContactUs";
import { NoPage } from "./pages/NoPage/NoPage";
function App() {
  return (
    <>

    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        {/* <Route path="/about-us" element={<AboutUs/>}/> */}
        {/* <Route path="/our-products" element={<OurProducts/>}/> */}
        {/* <Route path="/contact-us" element={<ContactUs/>}/> */}
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
