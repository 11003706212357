import './NoPage.scss'
import img from "../../assets/images/NoPage.png";
import { useNavigate } from "react-router-dom";

export const NoPage = () => {
    const navigate = useNavigate();
    return (
        <div className="noPage">
            <h2>Navrekh Tech</h2>
            <img src={img} alt="" />
            <p>Sorry, This page does not exist</p>
            <button className='btn' onClick={() => navigate("/")}>Go Back</button>
        </div>
    )
}
