import React from 'react'
import { Layout } from '../../components/Layouts/Layout'
import '../../assets/scss/style.scss';
import microsoft from '../../assets/images/microsoft.png'
import mongodb from '../../assets/images/mongodb.png'
import azure from '../../assets/images/azure.png';
import firebase from '../../assets/images/firebase.png';
import otpless from '../../assets/images/otpless.png';
import supabase from '../../assets/images/supabase.jpeg';
import { OurProducts } from '../../components/OurProducts/OurProducts';
import { ContactUs } from '../../components/ContactUs/ContactUs';
import { AboutUs} from '../../components/AboutUs/AboutUs';
import { Link, animateScroll as scroll } from 'react-scroll';
// import unipost from '../../assets/images/UniPostLogo.png';

export const Home = () => {
  
   const handleExplore = () => {
    const productsSection = document.querySelector("#products");
    if (productsSection) {
        productsSection.scrollIntoView({ behavior: "smooth" });
    }
}
  
  return (
    <Layout>
      <main>

        <section id='hero' className='hero'>
          <h1>Welcome to Navrekh Tech, where innovation meets accessibility.</h1>
          <p>Dive into a hub of groundbreaking apps that cater to your every need. Whether it's sports management with YourSportz, seamless communication with ChatVerse, or content management with UniPost, we've centralized everything for your convenience.</p>
          <button onClick={handleExplore} className='btn'>Explore Now</button>
        </section>



        <section className='trustedBy'>
          <div className="left">
            <h3>Trusted by Leaders in the Industry</h3>
            <p>Join a growing list of satisfied users who trust Navrekh Tech for their app needs. Recognized by leading companies and casual users alike. Trusted by industry leaders.</p>
            <button className='btn'>Get Started</button>
          </div>

          <div className="right">

            <div className="company">
              <img src={microsoft} alt="" />
              <p>Microsoft</p>
            </div>
            <div className="company">
              <img src={azure} alt="" />
              <p>Azure</p>
            </div>
            <div className="company">
              <img src={mongodb} alt="" />
              <p>MongoDb</p>
            </div>
            <div className="company">
              <img src={firebase} alt="" />
              <p>Firebase</p>
            </div>
            <div className="company">
              <img src={otpless} alt="" />
              <p>OtpLess</p>
            </div>

            <div className="company">
              <img src={supabase} alt="" />
              <p>Supabase</p>
            </div>

          </div>
        </section>


        <OurProducts/>
        
        <AboutUs/>
        
        
        <ContactUs/>




      </main>
    </Layout>
  )
}
