import React from 'react'
import '../Footer/Footer.scss';

import facebook from '../../assets/images/footer/facebook.png';
import youtube from '../../assets/images/footer/youtube.png';
import linkedin from '../../assets/images/footer/linkedin-logo.png';
import twitter from '../../assets/images/footer/twitter.png';

export const Footer = () => {
	return (
		<footer>
			<div className='left'>
				<div className='top'>
					<h2>Navrekh Tech</h2>
					<p>Stay Connected with Navrekh Tech for the Latest in App Innovation.</p>
				</div>
				<div className='social-icons'>
					<a href="#"><img src={facebook} alt="" /></a>
					<a href="#"><img src={youtube} alt="" /></a>
					<a href="https://www.linkedin.com/company/navrekh-technologies-pvt-ltd/" target="_blank"><img src={linkedin} alt="" /></a>
					<a href="#"><img src={twitter} alt="" /></a>
				</div>
			</div>
			<div className='right'>
				<ul>
					<li><a href="#">Product</a></li>
					<li><a href="#">Features</a></li>
					<li><a href="#">Integrations</a></li>
					<li><a href="#">Pricing</a></li>
					<li><a href="#">Changelog</a></li>
					<li><a href="#">Docs</a></li>
				</ul>
				<ul>
					<li><a href="#">Company</a></li>
					<li><a href="#">About us</a></li>
					<li><a href="#">Blog</a></li>
					<li><a href="#">Careers</a></li>
					<li><a href="#">Customers</a></li>
					<li><a href="#">Brand</a></li>
				</ul>
				<ul>
					<li><a href="#">Resources</a></li>
					<li><a href="#">Community</a></li>
					<li><a href="#">Contact</a></li>
					<li><a href="#">Privacy Policy</a></li>
					<li><a href="#">Terms of Services</a></li>
				</ul>
			</div>
		</footer>
	)
}
