import React from 'react';

import './AboutUs.scss';

import thunder from "../../assets/images/aboutUs/flash.png"
import group from "../../assets/images/aboutUs/multiple-users-silhouette.png"
import rocket from "../../assets/images/aboutUs/marketing.png"
import book from "../../assets/images/aboutUs/agenda.png"
import goal from "../../assets/images/ourGoal.jpg";
import vision from "../../assets/images/ourVision.jpg";


export const AboutUs = () => {
  return (
    <section id='about-us' className='about-us-container'>
      <div className='about-us-header'>
        {/* <h2>Navrekh Tech's Suite of Apps</h2> */}
        <h1>About Navrekh Tech</h1>

        <div className='header-description'>

          <div className="goalContent">
            <h3 className='goalH3'> Our Goal</h3>
            <div className='goalInner'>
              <img className='goalImg' src={goal} alt="" />
              <div className="goalpdiv">
                <p className='goalP'>Navrekh Technologies Pvt. Ltd. aims to become a leading innovator in the digital landscape by delivering exceptional, user-centric solutions. </p>

                <br />
                <p className='goalP'>
                  Our goal is to : Elevate the amateur sports community through YourSportz by providing a comprehensive multi-game platform that supports and celebrates their passion.Integrate the best features of WhatsApp, Facebook, and LinkedIn into ChatVerse, creating a unique and seamless communication experience for users worldwide.
                </p>
                <br />
                <p className='goalP'>
                  Simplify social media management with UniPosts, enabling users to effortlessly create and distribute content across all platforms with a single, intuitive tool.  Through these initiatives, we strive to enhance connectivity, improve user experiences, and drive technological advancement, ensuring our products positively impact the lives of our global user base.
                </p>
              </div>



            </div>
          </div>


          <div className="visionContent">
            <h3 className='visionH3'> Our Vision</h3>
            <div className='visionInner'>
              <img className='visionImg' src={vision} alt="" />

              <p className='visionP'>At Navrekh Technologies Pvt. Ltd., we envision a future where digital innovation empowers every individual and community to connect, share, and excel. Through our cutting-edge platforms - YourSportz, ChatVerse, and UniPosts - we aim to revolutionize the way amateur sports enthusiasts engage with their passions, streamline communication across diverse networks, and simplify social media interactions. We strive to create a cohesive ecosystem that enhances experiences, fosters connections, and unleashes the potential of every user in the digital world.</p>
            </div>
          </div>
          {/* With Navrekh Tech, you gain easy access to a suite of apps. Our engaging and accessible
            design ensures you find exactly what you need, without the hassle. */}
        </div>
      </div>

      <div className='about-us-content'>
        <div className='about-us-card'>
          <img src={thunder} alt='thunder' />
          <h3 className='card-title'>Convenience</h3>
          <p className='card-description'>
            Discover the convenience of our engaging and accessible design that ensures you find exactly what you need.
          </p>
        </div>

        <div className='about-us-card'>
          <img src={group} alt='group' />
          <h3 className='card-title'>Streamlined Tasks</h3>
          <p className='card-description'>
            Streamlining your daily tasks has never been easier with Navrekh Tech.
          </p>
        </div>

        <div className='about-us-card'>
          <img src={rocket} alt='rocket' />
          <h3 className='card-title'>Easy Access</h3>
          <p className='card-description'>
            Experience easy access to a suite of apps with Navrekh Tech.
          </p>
        </div>

        <div className='about-us-card'>
          <img src={book} alt='book' />
          <h3 className='card-title'>Simplified Experience</h3>
          <p className='card-description'>
            Find exactly what you need without the hassle, simplifying your life.
          </p>
        </div>

      </div>
    </section>
  );
};


