import React, { useState } from 'react'
import './ContactUs.scss'
import axios from 'axios'
export const ContactUs = () => {
    
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [msg, setMsg] = useState();
    
    const handleSubmit = async (e)=>{
        e.preventDefault()
        
        try {
            const data = {
                "firstName" : firstName,
                "lastName" : lastName,
                "email" : email,
                "message" : msg,
                
            }
            const res = await axios.post("https://yoursportzbackend.azurewebsites.net/api/web/navrekhTech/create", data)
            
            if(res.status === 201){
                alert("We have received your request and will get back to you Soon...")
            }
            
        } catch (error) {
            
            console.log(error)
        }
        
       
    }
    
    
    return (
            <section id='contactUs' className='contactUs'>
                <div className="contactLeft">
                    <h3>Contact Us</h3>
                    <p>Need to get in touch with us? fill out the form and we will get back to you!</p>
                </div>
                <div className="contactRight">
                    <form>
                    <div className="name">
                    
                    <div className="firstname">
                        <label htmlFor="firstName">First Name:</label><br />
                        <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" id="firstName" name="firstName" required /><br /><br />
                        
                    </div>
                    <div className="lastname">
                        <label htmlFor="lastName">Last Name:</label><br />
                        <input value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" id="lastName" name="lastName" required /><br /><br />
                        
                    </div>

                    </div>

                        <label htmlFor="email">Email:</label><br />
                        <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" id="email" name="email" required /><br /><br />

                        <label htmlFor="helpText">What can we help you with?</label><br />
                        <textarea value={msg} onChange={(e)=>setMsg(e.target.value)} id="helpText" name="helpText" rows="4" cols="50" required></textarea><br /><br />

                        <button onClick={handleSubmit} className='btn'>Submit</button>

                    </form>
                </div>
            </section>

    )
}
